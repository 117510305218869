<template>
    <!-- 商品列表 -->
    <div class="left_template">
        <!-- 搜索栏 -->
        <el-row class="searchCss" type="flex" justify="space-around">
            <el-col :span="4">
                <el-input size="small" v-model="name" placeholder="请输入关键词"></el-input>
            </el-col>
            <el-col :span="4">
                <el-input size="small" v-model="star" style="width:60px" placeholder="¥"></el-input>
                <span style="margin:0 5px">-</span>
                <el-input size="small" v-model="end" style="width:60px" placeholder="¥"></el-input>
            </el-col>
            <el-col :span="3">
                <el-input size="small" v-model="spu" placeholder="平台SPU"></el-input>
            </el-col>
            <el-col :span="3">
                <el-input size="small" v-model="sku" placeholder="平台SKU"></el-input>
            </el-col>
            <el-col :span="3">
                <el-input size="small" v-model="serial_number" placeholder="商品编号"></el-input>
            </el-col>
            <el-col :span="3">
                <el-input size="small" v-model="serial_code" placeholder="商品编码"></el-input>
            </el-col>
            <el-col :span="1">
                <el-button size="small" @click="searchBtn" type="danger">搜索</el-button>
            </el-col>
        </el-row>
        <!-- 商品z展示列表 -->
        <div class="flexBox">
            <div v-if="list.length == 0" class="noShopping">
             暂无商品
            </div>
            <div v-for="(item,index) in list" :key="index" class="bgcFFF">
                <div>
                    <el-image
                        @click="details(item)"
                        style="width: 192px; height: 192px"
                        :src="item.cover_url"
                    ></el-image>
                </div>
                <el-link @click="details(item)" :underline="false">
                    <p class="yichu">{{item.name||'--'}}</p>
                </el-link>
                <p>
                    <el-link @click="details(item)" :underline="false" class="red_16size">
                        <p>¥{{item.item_sku && item.item_sku.sale_price}}</p>
                    </el-link>
                </p>
            </div>
        </div>
        <div class="block">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.currentPage4"
                :page-sizes="[20]"
                :page-size="20"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.total"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import eventBus from '@/server/eventBus.js'

export default {
  data() {
    return {
      list: [],

      name: null,
      serial_number: null,
      serial_code: null,
      spu: null,
      sku: null,

      star: null,
      end: null,
      pages: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
        currentPage: 1
      }
    };
  },
  created() {
    this.query();
  },
   mounted() {
  //  调用分类，切换商品
		eventBus.$on('searchFenlei',(data)=>{
   
    let obj = {goods_type_id:data}
    this.query(obj)
		})
  },
  methods: {
    query(data) {
      let params = {
        ...data,
        page: this.pages.pageNum,
        page_size: this.pages.pageSize
      };
      this.$axios.postJSON("/app_shop/goods_center", params).then(res => {
        if (res.code == 200) {
          this.list = res.data.data;
          this.pages.total = res.data.total;
          this.pages.currentPage = res.data.current_page;
        }
      });
    },
    details(item) {
      console.log(item);
      this.$router.push({ path: "/commodityInfo", query: { item_sku_id: item.item_sku.id,app_goods_id:item.id } });
    },
    searchBtn() {
      let obj = {};
      if (this.star && this.end) {
        obj.sale_price = [];
        obj.sale_price[0] = Number(this.star);
        obj.sale_price[1] = Number(this.end);
      }
      if (this.name) {
        obj.name = this.name;
      }
      if (this.serial_number) {
        obj.serial_number = this.serial_number;
      }
      if (this.serial_code) {
        obj.serial_code = this.serial_code;
      }
      if (this.spu) {
        obj.spu = this.spu;
      }
      if (this.sku) {
        obj.sku = this.sku;
      }

      this.query(obj);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.searchCss {
  background-color: #fff;
  padding: 10px 30px;
}
.bgcFFF {
  background-color: #fff;
  padding: 16px;
  margin-top: 24px;
  margin-left: 10px;
  p {
    margin-top: 5px;
  }
}
.flexBox {
  min-height: 800px;
  width: 950px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // align-content: center;
  justify-content: flex-start;
  // align-items: center;
  align-items: flex-start;
  .noShopping{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
.red_16size {
  font-size: 16px;
  color: #f74848;
  font-weight: bold;
}
.yichu {
  width: 192px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.block {
  background-color: #fff;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
}
</style>