export const morePage = [
  {
    path: "/lookmore/news",
    title: "更多新闻",
    name: "lookmore_news",
    component: () => import("@/views/lookmore/news.vue"),
  },
  {
    path: "/lookmore/meeting",
    title: "更多会议",
    name: "lookmore_meeting",
    component: () => import("@/views/lookmore/meeting.vue"),
  },
];
