import Vue from "vue";
import store from "@/store/index.js";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import {
  mySelf
} from "./mySelf"; //个人中心
import {
  login
} from "./login";
import {
  shortlist
} from "./shortlist";
import {
  business
} from "./business";
import {
  hireHall
} from "./hireHall"; //招聘大厅
import shopStore from "./shopStore"; //商城
import { morePage } from "./morePage"; //apass 会议-新闻 更多页面
const routes = [
  ...morePage,
  ...shopStore,
  {
    path: "/mySelf",
    name: "mySelf",
    redirect: "/mySelf/index",
    component: () => import("@/views/mySelf/index.vue"),
    children: mySelf,
  },
  {
    path: "/loginIndex",
    redirect: "/login",
    name: "LoginIndex",
    component: () => import("@/views/login/index.vue"),
    children: login,
  },
  {
    path: "/register/agreement",
    name: "RegisterAgree",
    component: resolve => require(["@/views/login/register/platform/agreement"], resolve),
  },
  {
    path: "/business",
    name: "Business",
    redirect: "/BusinessHome",
    component: () => import("@/views/business/index.vue"),
    children: business,
  },
  {
    path: "/sucai",
    name: "sucai",
    component: () => import("@/views/sucai/index.vue"),
  },
  {
    path: "/keepAlive/myOrder",
    redirect: "/myProcured",
    name: "myOrderKA",
    title: "我的采购缓存",
    component: () => import("@/views/myCaiGou/keepAliveMO.vue"),
    children: [{
        path: "/myProcured",
        name: "myProcured",
        component: () => import("@/views/myCaiGou/index.vue"),
        children: [{
            path: "/myOrder/keepAlive",
            redirect: "/myOrder",
            name: "myOrderkeepAlive",
            title: "我的订单缓存",
            name: "keepAliveOrder",
            component: () => import("@/views/myCaiGou/myOrder/keepAliveOrder.vue"),
            children: [{
                path: "/myOrder",
                name: "我的订单",
                component: () => import("@/views/myCaiGou/myOrder/index.vue"),
              },
              {
                path: "/myOrder/detail",
                name: "我的订单 / 详情",
                component: () => import("@/views/myCaiGou/myOrder/detail/detail.vue"),
              },
              {
                path: "myOrder/pay/KeepAlive",
                name: "sorderOfflinePayAlive",
                redirect: "/myOrder/offlinePay",
                title: "我的订单_线下支付",
                component: () => import("@/views/myCaiGou/myOrder/pay/keepAlive.vue"),
                children: [{
                    path: "/myOrder/offlinePay",
                    name: "我的订单 / 支付",
                    title: "我的订单 / 支付",
                    component: () => import("@/views/myCaiGou/myOrder/pay/offlinePay.vue"),
                  },
                  {
                    path: "/myOrder/offlinePay/receipt",
                    name: "我的订单/线下支付/回单",
                    title: "我的订单_线下支付_回单",
                    component: () => import("@/views/myCaiGou/myOrder/pay/receiptGet.vue"),
                  },
                ]
              },
              {
                path: "/myOrder/offlineInvoice",
                name: "我的订单 / 发票",
                component: () => import("@/views/myCaiGou/myOrder/offlineInvoice/offlineInvoice.vue"),
              },
              {
                path: "/myOrder/sign",
                name: "我的订单 / 签收",
                component: () => import("@/views/myCaiGou/myOrder/sign/sign.vue"),
              },
            ]
          },
          {
            path: "/shoppingCar",
            name: "购物车", //购物车
            component: () => import("@/views/myCaiGou/myShopping/index.vue"),
          },
        ],
      },
      {
        path: "/confirmOrder",
        name: "确认订单", //确认订单
        component: () => import("@/views/myCaiGou/myShopping/confirmOrder.vue"),
      },
    ],
  },
  {
    path: "/large/newsManagement/preview",
    name: "preview", //预览
    component: () => import("@/views/preview/preview.vue"),
  },
  {
    path: "/shortlist",
    name: "shortlist",
    redirect: "/shortlist",
    component: () => import("@/views/shortlist/index.vue"),
    children: shortlist,
  },
  {
    path: "/hireHall",
    name: "shortlist",
    redirect: "/hireHall/list",
    component: () => import("@/views/hireHall/index.vue"),
    children: hireHall,
  },
  {
    path: "/",
    name: "indexPage",
    component: () => import("@/views/customPage/indexHomePage.vue"),
  },
  {
    path: "/shareApp",
    name: "shareApp",
    component: () => import("@/views/shareApp/index.vue"),
  },
  {
    path: "/share/:code",
    name: "share",
    component: () => import("@/views/share/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let name = to.name;
  console.log(name);
  let array = ["LoginIndex", "Login"];


  if (sessionStorage.getItem("AccessToken") && array.includes(name)) {
    next("/mySelf");
  } else {
    next();
  }
});

export default router;