<template>
  <canvas v-show="false" id="qrcode" :width="width" :height="width"></canvas>
</template>

<script>
import UQRCode from "uqrcodejs";
export default {
  data() {
    return {
      content: "",
      width: 200,
    };
  },
  methods: {
    open(val, callback) {
      if (!this.width) {
        this.width = val.width || 200;
      }
      if (val.content) {
        this.content = val.content;
      }
      // 获取uQRCode实例
      var qr = new UQRCode();
      // 设置二维码内容
      qr.data = this.content;
      // 设置二维码大小，必须与canvas设置的宽高一致
      qr.size = this.width;
      // 调用制作二维码方法
      qr.make();
      // 获取canvas元素
      var canvas = document.getElementById("qrcode");
      // 获取canvas上下文
      var canvasContext = canvas.getContext("2d");
      // 设置uQRCode实例的canvas上下文
      qr.canvasContext = canvasContext;
      // 调用绘制方法将二维码图案绘制到canvas上
      qr.drawCanvas();
      // 返回base64；
      callback(canvas.toDataURL());
    },
  },
};
</script>

<style></style>
