<template>
    <div class="page-box">
        <asideIcon v-if="showAside"></asideIcon>
        <userHeaderFix v-if="showHeader"></userHeaderFix>
        <div class="top-sec" style="margin-top: 3px;">
            <div class="static-tip">要 闻 中 心</div>
            <div class="banner-wrap">
                <!-- <div class="banner" v-for="item in bannerList" :key="item.id">
                    <p class="title ellipsis" @click="gotoDetail(item)" style="cursor: pointer;">{{ item.news_name }}
                    </p>
                    <p class="desc ellipsis">{{ item.introduction }}</p>
                </div> -->
                <el-carousel ref="bannerRef" indicator-position="none" :interval="6000" arrow="never"
                    direction="vertical" :height="'115px'" style="width: 100%">
                    <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                        <div class="banner">
                            <p class="title ellipsis" @click="gotoDetail(item)" style="cursor: pointer;">{{
                                item.news_name }}
                            </p>
                            <p class="desc ellipsis">{{ item.introduction }}</p>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="slider-six">
            <div class="slider-wrap">
                <div class="slider-content">
                    <!-- 轮播图 -->
                    <el-carousel ref="sliderRef" indicator-position="none" :interval="3000" arrow="never"
                        :height="'330px'" @change="sliderChange" style="width: 100%">
                        <el-carousel-item v-for="(item, index) in sliderList" :key="index">
                            <img class="slider-img" @click="gotoDetail(item)" style="cursor: pointer"
                                :src="item.cover && item.cover.image_url" alt="" srcset="" />
                        </el-carousel-item>
                    </el-carousel>
                    <!-- 轮播图六个小点 -->
                    <p class="slider-tip">
                        <span v-for="(v, i) in sliderList" :key="v.id" class="bit"
                            :class="{ active: sliderIndex === i }" @click="setSlider(i)"></span>
                    </p>
                </div>
                <!-- 轮播当前信息 移动端只显示轮播图 -->
                <div class="slider-info" @click="gotoDetail(curSlider)" style="cursor: pointer">
                    <div class="info-title ellipsis">
                        {{ curSlider.news_name }}
                    </div>
                    <div class="info-desc ellipsis2">
                        {{ curSlider.introduction || "暂无" }}
                    </div>
                    <div class="info-time">
                        {{ handleTime(curSlider.release_start_time) }}
                    </div>
                </div>
            </div>
            <div class="six-wrap">
                <div class="six-item" v-for="(item, index) in sliderList" :key="index" @click="gotoDetail(item)"
                    style="cursor: pointer">
                    <div class="six-title">
                        <span class="six-name ellipsis">{{ item.news_name }}</span>
                        <span class="time-tip">{{ handleTime(item.release_start_time) }}</span>
                    </div>
                    <div class="six-desc ellipsis">
                        {{ item.introduction || "--" }}
                    </div>
                </div>
            </div>
        </div>

        <div class="classify-wrap">
            <!-- 默认公告 -->
            <div class="default-box">
                <div class="default-header">
                    <div class="default-title">公告</div>
                    <div class="sec-search">
                        <i v-if="!inSearch" class="el-icon-search" @click="inSearch = true"
                            style="font-size: 20px; color: #999999"></i>
                        <el-input clearable v-else placeholder="关键字搜索" prefix-icon="el-icon-search" v-model="inSearchStr"
                            @blur="inSearchChange">
                        </el-input>
                    </div>
                </div>
                <div class="default-content">
                    <div class="default-item" v-for="(item, index) in defaultList" :key="index"
                        @click="gotoDetail(item)" style="cursor: pointer">
                        <div class="time">
                            <span class="day">{{ timeDay(item.release_start_time) }}</span>
                            <span class="month">{{ timeMonth(item.release_start_time) }}月</span>
                        </div>
                        <p class="title ellipsis">{{ item.news_name }}</p>
                    </div>
                </div>
                <div class="pager">
                    <el-button size="mini" @click="defaPageChange('first')">首页</el-button>
                    <el-button size="mini" @click="defaPageChange('pre')">上一页</el-button>
                    <el-button size="mini" @click="defaPageChange('next')">下一页</el-button>
                    <el-button size="mini" @click="defaPageChange('last')">尾页</el-button>
                    <el-input-number size="mini" style="margin: 0 12px 0 32px; width: 58px" v-model="defaVal"
                        :controls="false" :min="undefined"></el-input-number>
                    <span style="font-size: 12px; color: #606266" @click="defaPageChange()" type="text">确定</span>
                </div>
            </div>

            <div class="classify-more">
                <div class="classify-tabs" :class="{ showAll: moreCate }">
                    <div class="tab-item ellipsis" v-for="tab in categoryTabs" :key="tab.id"
                        @click="categoryChange(tab)">
                        <span :style="{ color: categoryId === tab.id ? '#0771C7' : '' }">{{ tab.name }}</span>
                    </div>
                </div>
                <div class="classify-tabs-more"
                    :style="{ display: categoryTabs && categoryTabs.length > 3 ? 'block' : 'none' }">
                    <div class="tab-item ellipsis" @click="moreCate = !moreCate">
                        <span>更多分类</span>
                    </div>
                </div>

                <div class="classify-content">
                    <div class="ctx-item" v-for="item in categoryList" :key="item.id">
                        <div class="left-bit"></div>
                        <div class="right-ctx" @click="gotoDetail(item)" style="cursor: pointer">
                            <div class="title ellipsis">{{ item.news_name }}</div>
                            <div class="time">{{ handleTime(item.release_start_time) }}</div>
                        </div>
                    </div>
                </div>

                <div class="pager">
                    <el-button size="mini" @click="catePageChange('first')">首页</el-button>
                    <el-button size="mini" @click="catePageChange('pre')">上一页</el-button>
                    <el-button size="mini" @click="catePageChange('next')">下一页</el-button>
                    <el-button size="mini" @click="catePageChange('last')">尾页</el-button>
                    <el-input-number size="mini" style="margin: 0 12px 0 32px; width: 58px" v-model="cateVal"
                        :controls="false" :min="undefined"></el-input-number>
                    <span style="font-size: 12px; color: #606266" @click="catePageChange()" type="text">确定</span>
                </div>
            </div>
        </div>

        <qianBottomNav ref="qianBottomNav" :data="bottomData" :lookType="lookType" />
    </div>
</template>

<script>
import asideIcon from "@/components/asideIcon";
// import footers from "@/components/footer/footer_tpl.vue";
import qianBottomNav from "./components/qianBottomNav.vue";
import userHeaderFix from "../preview/userHeaderFix";
export default {
    components: {
        userHeaderFix,
        qianBottomNav,
        asideIcon,
    },
    name: "business_more_news",
    data() {
        return {
            lookType: 1,
            showList: [],
            company_id: 0,
            bannerList: [],
            sliderList: [],
            sliderIndex: -1,
            toLeft: 0, // 0:无动画 1:左滑 2:右滑
            curSlider: {},

            defaultList: [],
            categoryTabs: [],
            categoryList: [],
            categoryId: 0,
            bottomData: {
                themeType: "black",
                themeColor: "#5087EC",
            },
            defa: { page: 1, page_size: 6, total: 0 },
            defaVal: 1,
            pageCate: { page: 1, page_size: 6, total: 0 },
            cateVal: 1,

            inSearch: false,
            inSearchStr: "",
            moreCate: false,
            showHeader: true,
            showAside: false
        };
    },
    created() {
        let userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
        this.company_id = userInfo?.company?.id || 0;
        if (window.parent == window) {
            this.showHeader = true;
            // 当前页面不在iframe中
        } else {
            this.showHeader = false;
            // 当前页面在iframe或者frameset中
        }
        this.query();
        this.getCategory();
    },
    methods: {
        inSearchChange() {
            const params = {
                company_id: this.company_id || 0,
                page: this.defa.page,
                page_size: this.defa.page_size,
                new_name_or_introduction: this.inSearchStr,
            };
            this.getNews({ ...params }, "defaultList");
        },
        defaPageChange(type) {
            const max = Math.ceil(this.defa.total / this.defa.page_size) || 1;
            if (type === "first") {
                this.defa.page = 1;
            } else if (type === "pre") {
                this.defa.page--;
            } else if (type === "next") {
                this.defa.page++;
            } else if (type === "last") {
                this.defa.page = max;
            } else {
                this.defa.page = this.defaVal;
            }

            console.log(JSON.stringify(this.defa));
            if (this.defa.page <= 1) this.defa.page = 1;
            if (this.defa.page >= max) this.defa.page = max;
            const params = {
                company_id: this.company_id || 0,
                page: this.defa.page,
                page_size: this.defa.page_size,
            };
            this.defaVal = this.defa.page
            this.getNews({ ...params }, "defaultList");
        },
        catePageChange(type) {
            this.moreCate = false
            const max = Math.ceil(this.pageCate.total / this.pageCate.page_size) || 1;
            if (type === "first") {
                this.pageCate.page = 1;
            } else if (type === "pre") {
                this.pageCate.page--;
            } else if (type === "next") {
                this.pageCate.page++;
            } else if (type === "last") {
                this.pageCate.page = max;
            } else {
                this.pageCate.page = this.cateVal;
            }

            console.log(JSON.stringify(this.defa));
            if (this.pageCate.page <= 1) this.pageCate.page = 1;
            if (this.pageCate.page >= max) this.pageCate.page = max;
            this.cateVal = this.pageCate.page
            this.categoryChange();
        },
        timeDay(time) {
            if (!time || !time.slice) return "";
            return time.slice(8, 10);
        },
        timeMonth(time) {
            if (!time || !time.slice) return "";
            return Number(time.slice(5, 7));
        },
        setSlider(index) {
            this.curSlider = this.sliderList[index];
            this.sliderIndex = index;
            this.$refs.sliderRef.setActiveItem(index);
        },
        sliderChange(index) {
            this.curSlider = this.sliderList[index];
            this.sliderIndex = index;
        },
        gotoDetail(item) {
            // https://aa.testfw.cn/main/_news/large/newsManagement/preview?id=102
            if (window.parent == window) {
                window.open("/large/newsManagement/preview?id=" + item.id);
                // 当前页面不在iframe中
            } else {
                this.$emit("openlinks", "$new/large/newsManagement/preview?id=" + item.id + "&name=" + (item.name || item.news_name));
                // 当前页面在iframe或者frameset中
            }
        },
        query() {
            const params = {
                company_id: this.company_id || 0,
                page: 1,
                page_size: 6,
            };
            this.getNews(params, "bannerList");
            this.getNews(params, "sliderList");
            this.getNews({ ...params }, "defaultList");
        },
        async getNews(params, field) {
            const apiMap = {
                bannerList: "/news_manage/public/get_workbench_data",
                sliderList: "/news_manage/public/get_workbench_data_more1",
                defaultList: "/news_manage/public/get_workbench_data_more2",
                categoryList: "/news_manage/public/get_workbench_data_more3",
            };
            // const api = "/news_manage/news/release_list"
            const api = apiMap[field];
            try {
                const res = await this.$axios.postJSON(api, params);
                if (res.code !== 200) return;
                const list = Array.isArray(res.data) ? res.data : Array.isArray(res.data.data) ? res.data.data : [];
                this[field] = list;
                if (field === "defaultList") {
                    this.defa.total = res.data.total;
                } else if (field === "categoryList") {
                    this.pageCate.total = res.data.total;
                }
            } catch (error) {
                console.log(error);
            }
        },
        categoryChange(category) {
            if (category) {
                this.categoryId = category.id;
                this.pageCate.page = 1;
                this.pageCate.total = 0;
            }
            const params = {
                category_id: [this.categoryId],
                company_id: this.company_id || 0,
                page: this.pageCate.page,
                page_size: this.pageCate.page_size,
            };
            this.getNews(params, "categoryList");
        },
        getCategory() {
            this.$axios
                // .get("/common/categoryGroup/category/list?modular=news_manage")
                .postJSON("/news_manage/public/get_workbench_data_morecateogry")
                .then((res) => {
                    const data = res.data.filter((v) => v.name !== "公告");
                    const items = data.map((v) => ({
                        id: v.id,
                        name: v.path_name,
                    }));
                    this.categoryTabs = items
                    this.categoryChange(this.categoryTabs[0]);
                });
        },
        //手机端时间取到天
        handleTime(timeStr) {
            if (timeStr) {
                return timeStr.split(" ")[0];
            }
            return timeStr || "--";
        },
        //手机端新闻分类最多显示8字符
        handlName(nameStr, val) {
            if (this.lookType == 1) {
                if (nameStr && nameStr.length > (val ? val : 20)) {
                    return nameStr.slice(0, val ? val : 20) + "...";
                }
            } else {
                if (nameStr && nameStr.length > (val ? val : 8)) {
                    return nameStr.slice(0, val ? val : 8) + "...";
                }
            }
            return nameStr || "--";
        },
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;

    .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ellipsis2 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .top-sec {
        width: 100%;
        box-sizing: border-box;
        height: 115px;
        background-color: rgba(246, 246, 246, 1);
        text-align: center;
        padding-left: 137px;
        display: flex;
        justify-content: flex-start;

        .static-tip {
            width: 115px;
            height: 115px;
            line-height: 35px;
            background-color: rgba(1, 82, 147, 1);
            color: rgba(255, 255, 255, 1);
            font-size: 24px;
            text-align: center;
            font-family: Roboto;
            padding: 20px;
            box-sizing: border-box;
        }

        .banner-wrap {
            flex: 1;
            overflow: hidden;
        }

        .banner {
            height: 115px;
            padding: 18px 0 8px 38px;
            box-sizing: border-box;

            .title {
                color: rgba(1, 82, 147, 1);
                font-size: 28px;
                text-align: left;
                font-family: SourceHanSansSC-regular;
            }

            .desc {
                padding-left: 8px;
                color: rgba(51, 51, 51, 1);
                font-size: 14px;
                text-align: left;
                font-family: SourceHanSansSC-light;
            }
        }
    }

    .slider-six {
        width: 100%;
        height: 500px;
        display: flex;

        .slider-wrap {
            box-sizing: border-box;
            width: 50%;
            height: 100%;
            padding: 20px 20px 27px;
            background-image: linear-gradient(white 50%, #015293 50%);
            // background-size: 250px 250px;

            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .slider-content {
            width: 564px;
            height: 360px;
            overflow: hidden;
            display: flex;
            flex-wrap: nowrap;
            position: relative;

            .slider-img {
                width: 564px;
                height: 330px;
                object-fit: fill;
                cursor: pointer;
            }

            .slider-tip {
                position: absolute;
                left: 0;
                bottom: 0;
                height: 25px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .bit {
                    display: block;
                    width: 10px;
                    height: 10px;
                    margin: 0 5px;
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 1);
                    cursor: pointer;

                    &.active {
                        background-color: #015293;
                        background-color: #4387f8;
                    }
                }
            }
        }

        .slider-info {
            width: 564px;
            height: 100px;
            box-sizing: border-box;
            padding: 0 20px 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: white;

            .info-title {
                font-size: 18px;
            }

            .info-desc {
                flex: 1;
                font-size: 14px;
            }

            .info-time {
                color: rgba(204, 204, 204, 1);
                font-size: 14px;
            }
        }

        .six-wrap {
            box-sizing: border-box;
            width: 50%;
            height: 100%;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .six-item {
            max-width: 560px;
            height: calc(100% / 6);
            border-bottom: 1px solid rgba(220, 224, 231, 1);
            padding: 15px 0;

            .six-title {
                color: rgba(1, 82, 147, 1);
                font-size: 18px;
                text-align: left;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                .six-name {
                    flex: 1;
                    display: block;
                }

                .time-tip {
                    color: rgba(204, 204, 204, 1);
                    font-size: 14px;
                }
            }
        }
    }

    .classify-wrap {
        width: 100%;
        height: 500px;
        display: flex;
        margin-top: 20px;

        .default-box {
            box-sizing: border-box;
            width: 50%;
            height: 100%;
            padding: 20px 20px 27px;

            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .default-header {
            width: 100%;
            display: flex;
            max-width: 500px;

            .default-title {
                color: rgba(16, 16, 16, 1);
                font-size: 20px;
                font-weight: 800;
                padding-bottom: 3px;
                border-bottom: 2px solid rgba(1, 82, 147, 1);
            }

            .sec-search {
                display: flex;
                align-items: center;
                padding-left: 18px;

                /deep/ .el-input__inner {
                    border-radius: 20px;
                }
            }
        }

        .default-content {
            width: 100%;
            max-width: 500px;
            min-height: 312px;
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .default-item {
                width: 100%;
                height: 52px;
                display: flex;
                align-items: center;

                .time {
                    width: 34px;
                    height: 34px;
                    border-radius: 3px;
                    background-color: rgba(187, 187, 187, 1);
                    color: rgba(255, 255, 255, 1);
                    text-align: center;
                    margin-right: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    line-height: 12px;

                    .day {
                        font-size: 16px;
                    }

                    .month {
                        font-size: 10px;
                    }
                }

                .title {
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                }
            }
        }

        .classify-more {
            box-sizing: border-box;
            max-width: 538px;
            width: 50%;
            height: 100%;
            padding: 20px 20px 0;
            display: flex;
            flex-direction: column;
            position: relative;
            padding-top: 35px;
            align-items: flex-end;
        }

        .classify-tabs {
            background: #f5f5f5;
            max-width: 600px;
            padding: 0 20px;
            position: absolute;
            top: 20px;
            left: 0;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 35px;
            overflow: hidden;
            justify-content: flex-start;

            &.showAll {
                overflow: visible;
            }

            .tab-item {
                width: 80px;
                height: 35px;
                box-sizing: border-box;
                color: rgba(102, 102, 102, 1);
                font-size: 20px;
                padding-bottom: 3px;
                border-bottom: 2px solid #015293;
                cursor: pointer;
                margin-right: 28px;
                background: #f5f5f5;
            }
        }

        .classify-tabs-more {
            background: #f5f5f5;
            position: absolute;
            top: 20px;
            right: 0;
            height: 35px;
            display: none;

            .tab-item {
                width: 80px;
                height: 35px;
                box-sizing: border-box;
                color: rgba(102, 102, 102, 1);
                font-size: 20px;
                padding-bottom: 3px;
                border-bottom: 2px solid #015293;
                cursor: pointer;
                margin-bottom: 6px;
            }
        }

        .classify-content {
            width: 100%;
            max-width: 500px;
            min-height: 312px;
            margin-top: 45px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .ctx-item {
                width: 100%;
                height: 52px;
                display: flex;
                align-items: center;

                .left-bit {
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: rgba(180, 180, 182, 1);
                    align-self: self-start;
                    margin: 15px 10px 0 0;
                }

                .time {
                    color: rgba(1, 82, 147, 1);
                    font-size: 12px;
                }

                .title {
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
