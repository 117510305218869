<template>
    <div class="page-box">
        <asideIcon v-if="showAside"></asideIcon>
        <userHeaderFix v-if="showHeader"></userHeaderFix>
        <div class="top-sec" style="margin-top: 3px;">
            会议公告
        </div>
        <div class="slider-six">
            <div class="slider-wrap">
                <div class="cur-meeting">
                    <div class="meeting" v-if="!curMeeting.serial_number">
                        暂无会议
                    </div>
                    <div class="meeting" v-else>
                        <p class="tip">
                            <span>{{ curMeeting.serial_number }}</span>
                            <span class="status" :class="[curMeeting.status == 1 ? 'green' : 'warning']">
                                {{ curMeeting.status == 1 ? '进行中' : '待开始' }}
                            </span>
                        </p>
                        <p class="title ellipsis2" @click="gotoDetail(curMeeting)" style="cursor: pointer;">{{
                            curMeeting.name }}</p>
                        <p class="room ellipsis">{{ curMeeting.asset_name }}</p>
                        <p class="address ellipsis">
                            <i class="el-icon-location" style="color: #4387F8;font-size: 20px;"></i>
                            <span>{{ curMeeting.asset_address_pivot && curMeeting.asset_address_pivot.address || '--'
                                }}</span>
                        </p>
                        <p class="time-range">{{ `${curMeeting.meeting_start_date} 至 ${curMeeting.meeting_end_date}` }}
                        </p>
                        <p style="margin-top: 35px;">
                            <span style="color: rgba(153, 153, 153, 1);margin-right: 15px;">主持人</span>
                            <span style="">{{
                                (curMeeting.meeting_host_personnel_pivot || []).map(v => v.meeting_obj_name).join('、')
                                ||
                                '--'
                            }}
                            </span>
                        </p>
                        <p style="margin: 35px 0 12px 0;">
                            <span style="color: rgba(153, 153, 153, 1);margin-right: 15px;">参会部门</span>
                        </p>
                        <div class="department" v-for="(item, index) in (curMeeting.meeting_dept_pivot || [])"
                            :key="index">
                            <p class="name ellipsis">{{ item.department_name || '--' }}</p>
                            <p class="staff ellipsis">
                                {{
                                    (item.meeting_personnel_pivot || []).map(v => v.meeting_obj_name).join('、') || '--'
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="gap-bootom"></div>
            </div>
            <div class="six-wrap">
                <div class="items">
                    <div class="six-item" v-for="(item, index) in sliderList" :key="index"
                        @click="() => curMeeting = item" :class="{ target: item.id === curMeeting.id }">
                        <div class="six-title">
                            <span class="six-name ellipsis">{{ item.name }}</span>
                            <span class="time-tip">
                                <span v-if="!item.meeting_dept_pivot || !item.meeting_dept_pivot.length">--</span>
                                <span v-else>{{ item.meeting_dept_pivot[0].department_name }}</span>
                            </span>
                        </div>
                        <div class="six-desc ellipsis">
                            {{ `${handleTime(item.meeting_start_date)} 至 ${handleTime(item.meeting_end_date)}` }}
                            {{ `·${item.asset_name || '--'}` }}
                        </div>
                    </div>
                </div>
                <div class="gap-bootom"></div>
            </div>
        </div>

        <div class="classify-wrap">
            <div class="out-box">
                <div class="sec-header">
                    <div class="sec-title">
                        外部会议
                    </div>
                    <div class="sec-search">
                        <i v-if="!outSearch" class="el-icon-search" @click="outSearch = true"
                            style="font-size: 20px;color: #999999;"></i>
                        <el-input clearable v-else placeholder="关键字搜索" prefix-icon="el-icon-search" v-model="outSearchStr"
                            @blur="outSearchChange">
                        </el-input>
                    </div>
                </div>
                <div class="out-content">
                    <div class="sec-list">
                        <div v-for="item in outList" :key="item.id" class="sec-item">
                            <div>
                                <img class="left-icon" v-if="item.icon && item.icon.startsWith('http')" :src="item.icon"
                                    alt="" />
                                <img class="left-icon" v-else src="@/assets/img/business/icon_meeting.png" alt="" />
                            </div>
                            <div class="right-content">
                                <p class="ctx-name" @click="gotoDetail(item)" style="cursor: pointer;">{{ item.name }}
                                </p>
                                <div class="time-room">
                                    <span>{{ handleTime(item.meeting_start_date) }} — {{
                                        handleTime(item.meeting_end_date) }}</span>
                                    <span class="ellipsis">·{{ item.asset_name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pager">
                    <el-button size="mini" @click="defaPageChange('first')">首页</el-button>
                    <el-button size="mini" @click="defaPageChange('pre')">上一页</el-button>
                    <el-button size="mini" @click="defaPageChange('next')">下一页</el-button>
                    <el-button size="mini" @click="defaPageChange('last')">尾页</el-button>
                    <el-input-number size="mini" style="margin: 0 12px 0 32px;width: 58px;" v-model="defaVal"
                        :controls="false" :min="undefined"></el-input-number>
                    <span style="font-size: 12px;color: #606266;" @click="defaPageChange()" type="text">确定</span>
                </div>
            </div>

            <div class="classify-more">
                <div class="sec-header">
                    <div class="sec-title">
                        内部会议
                    </div>
                    <div class="sec-search">
                        <i v-if="!inSearch" class="el-icon-search" @click="inSearch = true"
                            style="font-size: 20px;color: #999999;"></i>
                        <el-input clearable v-else placeholder="关键字搜索" prefix-icon="el-icon-search" v-model="inSearchStr"
                            @blur="inSearchChange">
                        </el-input>
                    </div>
                </div>
                <div class="classify-tabs">
                    <div class="tab-item ellipsis" v-for=" tab in categoryTabs" :key="tab.id"
                        :class="[categoryId === tab.id ? 'active' : '']" @click="categoryChange(tab)">
                        <span>{{ tab.name }}</span>
                    </div>
                </div>

                <div class="classify-content">
                    <div class="sec-list">
                        <div v-for="item in categoryList" :key="item.id" class="sec-item">
                            <div>
                                <img class="left-icon" v-if="item.icon && item.icon.startsWith('http')" :src="item.icon"
                                    alt="" />
                                <img class="left-icon" v-else src="@/assets/img/business/icon_meeting.png" alt="" />
                            </div>
                            <div class="right-content">
                                <p class="ctx-name" @click="gotoDetail(item)" style="cursor: pointer;">{{ item.name }}
                                </p>
                                <div class="time-room">
                                    <span>{{ handleTime(item.meeting_start_date) }} — {{
                                        handleTime(item.meeting_end_date) }}</span>
                                    <span class="ellipsis">·{{ item.asset_name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pager">
                    <el-button size="mini" @click="catePageChange('first')">首页</el-button>
                    <el-button size="mini" @click="catePageChange('pre')">上一页</el-button>
                    <el-button size="mini" @click="catePageChange('next')">下一页</el-button>
                    <el-button size="mini" @click="catePageChange('last')">尾页</el-button>
                    <el-input-number size="mini" style="margin: 0 12px 0 32px;width: 58px;" v-model="cateVal"
                        :controls="false" :min="undefined"></el-input-number>
                    <span style="font-size: 12px;color: #606266;" @click="catePageChange()" type="text">确定</span>
                </div>

            </div>

        </div>

        <qianBottomNav ref="qianBottomNav" :data="bottomData" :lookType="lookType" />
    </div>
</template>

<script>
import asideIcon from "@/components/asideIcon";
// import footers from "@/components/footer/footer_tpl.vue";
import qianBottomNav from "./components/qianBottomNav.vue";
import userHeaderFix from "../preview/userHeaderFix";
export default {
    components: {
        userHeaderFix,
        qianBottomNav,
        asideIcon,
    },
    name: "business_meeting_more",
    data() {
        return {
            lookType: 1,
            curMeeting: {},
            showList: [],
            company_id: 0,
            sliderList: [],
            sliderIndex: -1,
            toLeft: 0, // 0:无动画 1:左滑 2:右滑
            curSlider: {},

            outList: [],
            categoryTabs: [
                // "meeting_type":[1],//会议类型1普通会议，2周期会议,3快捷会议
                { name: "全部", id: 0 },
                { name: "快捷会议", id: 3 },
                { name: "周期会议", id: 2 },
            ],
            categoryList: [],
            categoryId: 0,
            bottomData: {
                themeType: "black",
                themeColor: "#5087EC",
            },
            defa: { page: 1, page_size: 6, total: 0 },
            defaVal: 1,
            pageCate: { page: 1, page_size: 5, total: 0 },
            cateVal: 1,
            outSearch: false,
            outSearchStr: '',

            inSearch: false,
            inSearchStr: '',
            showHeader: true,
            showAside: false
        };
    },
    created() {
        let userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
        this.company_id = userInfo?.company?.id || 0;
        if (window.parent == window) {
            this.showHeader = true;
            // 当前页面不在iframe中
        } else {
            this.showHeader = false;
            // 当前页面在iframe或者frameset中
        }
        this.query();
    },
    methods: {
        outSearchChange() {
            const params = {
                company_id: this.company_id || 0,
                page: this.defa.page,
                page_size: this.defa.page_size,
                name: this.outSearchStr
            }
            this.getNews({ ...params, is_external: 1 }, 'outList')
        },
        inSearchChange() {
            this.categoryChange()
        },
        defaPageChange(type) {
            const max = Math.ceil(this.defa.total / this.defa.page_size) || 1
            if (type === 'first') {
                this.defa.page = 1;
            } else if (type === 'pre') {
                this.defa.page--;
            } else if (type === 'next') {
                this.defa.page++;
            } else if (type === 'last') {
                this.defa.page = max;
            } else {
                this.defa.page = this.defaVal;
            }

            console.log(JSON.stringify(this.defa))
            if (this.defa.page <= 1) this.defa.page = 1;
            if (this.defa.page >= max) this.defa.page = max;
            const params = {
                company_id: this.company_id || 0,
                page: this.defa.page,
                page_size: this.defa.page_size,
            }
            this.defaVal = this.pageCate.page
            this.getNews({ ...params, }, 'outList')
        },
        catePageChange(type) {
            const max = Math.ceil(this.pageCate.total / this.pageCate.page_size) || 1
            if (type === 'first') {
                this.pageCate.page = 1;
            } else if (type === 'pre') {
                this.pageCate.page--;
            } else if (type === 'next') {
                this.pageCate.page++;
            } else if (type === 'last') {
                this.pageCate.page = max;
            } else {
                this.pageCate.page = this.cateVal;
            }

            console.log(JSON.stringify(this.defa))
            if (this.pageCate.page <= 1) this.pageCate.page = 1;
            if (this.pageCate.page >= max) this.pageCate.page = max;
            this.cateVal = this.pageCate.page
            this.categoryChange()
        },
        timeDay(time) {
            if (!time || !time.slice) return ''
            return time.slice(8, 10)
        },
        timeMonth(time) {
            if (!time || !time.slice) return ''
            return Number(time.slice(5, 7))
        },
        setSlider(index) {
            this.curSlider = this.sliderList[index];
            this.sliderIndex = index;
            this.$refs.sliderRef.setActiveItem(index)
        },
        sliderChange(index) {
            this.curSlider = this.sliderList[index];
            this.sliderIndex = index;
        },
        gotoDetail(item) {
            window.open(`${location.origin}/main/_dm/large/homepage?id=` + item.id);
            // // https://aa.testfw.cn/main/_news/large/newsManagement/preview?id=102
            // if (window.parent == window) {
            //     window.open("/large/newsManagement/preview?id=" + item.id);
            //     // 当前页面不在iframe中
            // } else {
            //     this.$emit("openlinks", "$new/large/newsManagement/preview?id=" + item.id + '&name=' + (item.name || item.news_name));
            //     // 当前页面在iframe或者frameset中
            // }
        },
        query() {
            const params = {
                company_id: this.company_id || 0,
                page: 1,
                page_size: 6,
            }
            this.getNews({ ...params, page_size: 7, status: [0, 1] }, 'sliderList')
            this.getNews({ ...params, is_external: 1 }, 'outList')
            this.categoryChange({ name: "全部", id: 0 })
        },
        async getNews(params, field) {
            const api = "/asset_manage/public/meeting_choice"
            // const api = "/asset_manage/public/get_workbench_meeting"
            try {
                const res = await this.$axios.postJSON(api, params);
                if (res.code !== 200) return
                this[field] = res.data.data
                if (field === 'outList') {
                    this.defa.total = res.data.total
                } else if (field === 'categoryList') {
                    this.pageCate.total = res.data.total
                } else if (field === 'sliderList') {
                    this.curMeeting = res.data.data[0] || {}
                }
            } catch (error) {
                console.log(error)
            }
        },
        categoryChange(category) {
            if (category) {
                this.categoryId = category.id
                this.pageCate.page = 1
                this.pageCate.total = 0
            }
            const params = {
                meeting_type: this.categoryId ? [this.categoryId] : [],
                company_id: this.company_id || 0,
                page: this.pageCate.page,
                page_size: this.pageCate.page_size,
                name: this.inSearchStr,
                is_external: 0
            }
            this.getNews(params, 'categoryList')
        },
        //手机端时间取到天
        handleTime(timeStr) {
            if (timeStr) {
                return timeStr.split(" ")[0];
            }
            return timeStr || "--";
        },
        //手机端新闻分类最多显示8字符
        handlName(nameStr, val) {
            if (this.lookType == 1) {
                if (nameStr && nameStr.length > (val ? val : 20)) {
                    return nameStr.slice(0, val ? val : 20) + "...";
                }
            } else {
                if (nameStr && nameStr.length > (val ? val : 8)) {
                    return nameStr.slice(0, val ? val : 8) + "...";
                }
            }
            return nameStr || "--";
        },
    },
}
</script>

<style lang="scss" scoped>
.page-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;

    .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ellipsis2 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .top-sec {
        width: 100%;
        box-sizing: border-box;
        height: 80px;
        line-height: 80px;
        // background-color: rgba(246, 246, 246, 1);
        text-align: center;
        color: rgba(16, 16, 16, 1);
        font-weight: 800;
        font-size: 32px;
    }

    .slider-six {
        width: 100%;
        height: 618px;
        display: flex;

        .slider-wrap {
            box-sizing: border-box;
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .cur-meeting {
            box-sizing: border-box;
            max-width: 605px;
            height: 100%;
            padding: 0 15px;
        }

        .meeting {
            box-sizing: border-box;
            width: 575px;
            height: 100%;
            padding: 28px 32px;
            line-height: 20px;
            border-radius: 8px;
            background-color: rgba(242, 246, 253, 1);
            color: rgba(51, 51, 51, 1);
            font-size: 14px;
            text-align: left;
            font-family: Roboto;

            .tip {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                color: #666666;
                font-size: 14px;

                .status {
                    display: block;
                    width: 60px;
                    height: 24px;
                    line-height: 24px;
                    border-radius: 3px;
                    background-color: #adadad;
                    text-align: center;
                    color: white;

                    &.green {
                        background-color: #7ac756;
                    }

                    &.warning {
                        background-color: #e99d42;
                    }
                }
            }

            .title {
                max-height: 75px;
                line-height: 37px;
                color: rgba(16, 16, 16, 1);
                font-size: 24px;
                font-weight: 800;
                margin-top: 16px;
            }

            .room {
                color: rgba(51, 51, 51, 1);
                font-size: 14px;
                margin-top: 20px;
            }

            .address {
                color: rgba(51, 51, 51, 1);
                font-size: 14px;
                margin-top: 12px;
            }

            .time-range {
                color: rgba(51, 51, 51, 1);
                font-size: 16px;
                font-weight: 800;
                margin-top: 32px;
            }

            .department {
                margin-bottom: 28px;

                .name {
                    width: 300px;
                    text-align: center;
                    height: 32px;
                    line-height: 32px;
                    border-radius: 2px;
                    background-color: rgba(245, 245, 245, 1);
                }

                .staff {
                    margin-top: 12px;
                }
            }
        }

        .six-wrap {
            box-sizing: border-box;
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .items {
            box-sizing: border-box;
            padding: 0 15px;
            max-width: 575px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .six-item {
                height: calc(100% / 7);
                box-sizing: border-box;
                padding: 15px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;

                &.target::before {
                    content: "";
                    position: absolute;
                    left: -18px;
                    top: calc(50% - 10px);
                    width: 4px;
                    height: 20px;
                    box-sizing: border-box;
                    line-height: 20px;
                    background-color: rgba(255, 78, 78, 1);
                    text-align: center;
                    border: 1px solid rgba(255, 78, 78, 1);
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #dce0e7;
                }

                .six-title {
                    color: rgba(1, 82, 147, 1);
                    font-size: 18px;
                    text-align: left;
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;

                    .six-name {
                        flex: 1;
                        display: block;
                    }

                    .time-tip {
                        color: rgba(204, 204, 204, 1);
                        font-size: 14px;
                    }
                }
            }
        }

        .gap-bootom {
            width: 100%;
            max-width: 590px;
            padding-top: 20px;
            border-bottom: 1px solid #dce0e7;
        }
    }

    .classify-wrap {
        width: 100%;
        height: 500px;
        display: flex;
        margin-top: 20px;

        .out-box {
            box-sizing: border-box;
            width: 50%;
            height: 100%;
            padding: 20px 20px 27px;

            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .sec-header {
            width: 100%;
            display: flex;
            max-width: 500px;

            .sec-title {
                color: rgba(16, 16, 16, 1);
                font-size: 20px;
                font-weight: 800;
                padding-bottom: 3px;
                border-bottom: 2px solid rgba(1, 82, 147, 1);
            }

            .sec-search {
                display: flex;
                align-items: center;
                padding-left: 18px;

                /deep/ .el-input__inner {
                    border-radius: 20px;
                }
            }
        }

        .sec-list {
            width: 100%;

            .sec-item {
                display: flex;
                align-items: flex-start;
                margin-bottom: 16px;

                .left-icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    margin-top: 2px;
                }

                .right-content {
                    height: 45px;
                    flex: 1;
                    overflow: hidden;

                    .ctx-name {
                        color: rgba(68, 68, 68, 1);
                        font-size: 14px;
                        font-weight: bold;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .time-room {
                        margin-top: 6px;
                        color: rgba(51, 51, 51, 1);
                        font-size: 12px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            // .sec-item:not(:nth-child(1)) {
            //   margin-bottom: 18px;
            // }
        }

        .out-content {
            width: 100%;
            max-width: 500px;
            min-height: 365px;
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }

        .classify-more {
            box-sizing: border-box;
            max-width: 500px;
            width: 50%;
            height: 100%;
            padding: 20px 20px 27px;
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: flex-end;
        }

        .classify-tabs {
            background: #f5f5f5;
            max-width: 500px;
            margin-top: 23px;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 35px;
            justify-content: flex-start;

            .tab-item {
                width: 80px;
                margin-right: 32px;
                text-align: center;
                padding: 0 6px;
                height: 30px;
                box-sizing: border-box;
                color: rgba(102, 102, 102, 1);
                font-size: 16px;
                padding-bottom: 3px;
                position: relative;
                cursor: pointer;

                &.active {
                    color: #0771C7;
                }

                &.active::after {
                    content: '';
                    display: block;
                    width: 60px;
                    height: 2px;
                    background: #015293;
                    position: absolute;
                    bottom: 0;
                    left: calc(50% - 30px);
                }
            }
        }

        .classify-content {
            width: 100%;
            max-width: 500px;
            min-height: 300px;
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

        }

    }
}
</style>