<template>
  <div>
    <div id="shareApp">
      <div class="main_box" v-if="info">
        <div class="title">
          <span>{{ info.app_name }}</span>
        </div>
        <div class="content">
          <div class="content_center">
            <div class="avatar">
              <el-image
                style="width: 100%; height: 100%"
                :src="info.app_icon.image_url"
                fit="fill"
              ></el-image>
            </div>
            <div class="release_number">
              <div class="release_number_mid">
                {{ release_number || "" }}
              </div>
            </div>
            <div class="copy">
              <el-button type="primary" round class="btn" size="mini" @click="copyUrl">
                <span style="font-weight: bold;">复制链接</span>
              </el-button>
            </div>
            <div class="tips">
              扫描下方二维码下载app
            </div>
            <div class="cornered-div">
              <div class="corner-borders">
                <!-- 四个边框角 -->
                <div class="corner_1"></div>
                <div class="corner_2"></div>
                <div class="corner_3"></div>
                <div class="corner_4"></div>
                <div class="qr">
                  <img :src="qrCode" alt="" />
                </div>
              </div>
            </div>
            <div class="donw_btn">
              <div @click="downloadBtn">
                {{ type == 1 ? "苹果" : "安卓" }}下载 &nbsp;
                <i class="fa fa-download"></i>
              </div>
            </div>
            <div class="tips" style="color:#101010">
              {{ info.organization_name }}
            </div>
            <div class="footer">
              <div class="type_box bor_rr">
                <span @click="choseOther(1)" :class="[type == 1 ? 'chose' : '', 'type_title']">
                  <i class="fa fa-apple" />
                  苹果
                </span>
              </div>
              <div class="type_box">
                <span @click="choseOther(2)" :class="[type == 2 ? 'chose' : '', 'type_title']">
                  <i class="fa fa-android" />
                  安卓
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 生成二维码 -->
    <createQrcode ref="createQrcode" />
  </div>
</template>

<script>
import createQrcode from "@/components/createQrcode/createQrcode.vue";
export default {
  name: "shareApp",
  components: {
    createQrcode,
  },
  props: {},
  created() {
    this.query();
    if (this.$route.query.download == "ios") {
      this.type = 1;
    }
    if (this.$route.query.download == "android") {
      this.type = 2;
    }
  },
  mounted() {},
  data() {
    return {
      info: null,
      android: null,
      ios: null,
      qrCode: null,
      type: 2,
      release_number: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    query() {
      let code = this.$route.params.code;
      // let id = "RyQhTuPA";
      this.$axios.postJSON(`/gn/public/get_share?code=${code}`, { code: code }).then(res => {
        if (res.code !== 200) return
        console.log("res==>", res)
        const url = res.data.param
        window.location.href = url
      });
    },
    download(url) {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none"; // 隐藏 iframe
      iframe.src = url;
      document.body.appendChild(iframe);
      setTimeout(() => {
        document.body.removeChild(iframe); // 下载完成后移除 iframe
      }, 1000);
    },
    choseOther(val) {
      this.type = val;
    },
    downloadBtn() {
      if (this.type == 1) {
        this.download(this.ios);
      }
      if (this.type == 2) {
        this.download(this.android.image_url);
      }
    },
    copyUrl() {
      let url = window.location.href;
      console.log("url==>", url);
      this.copy(url);
    },
    copy(v) {
      //复制
      console.log(v);
      let copy = v;
      let domInput = document.createElement("input");
      domInput.value = copy;
      document.body.appendChild(domInput);
      domInput.select();
      document.execCommand("Copy");
      this.$message({
        message: "已复制粘贴板",
        type: "success",
      });
      domInput.remove();
    },
  },
};
</script>
<style lang="scss" scoped>
#shareApp {
  background-color: #fff;
  // max-width: 1200px;
  // margin: 0 auto;
  box-sizing: border-box;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(360deg, #dfeaff 0%, #97bdfa 55%, #4287f6 100%);
  .main_box {
    width: calc(100%);
    height: 100%;
    flex: 1;
    // background-color: #4387f8;
    padding-top: calc(100vw * 42 / 1920);
    box-sizing: border-box;
  }
  .title {
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    span {
      color: #fff;
      font-size: 22px;
      font-weight: bolder;
    }
  }
  .content {
    height: calc(100% - 60px);
    width: 100%;
    padding: calc(100vw * 42 / 1920);
    margin-top: calc(100vw * 10 / 1920);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    .content_center {
      width: calc(300px);
      height: calc(100% - 60px);
      background-color: #fff;
      border-radius: 20px;
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      position: relative;
      .avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: #fff 6px solid;
        position: absolute;
        top: calc(-7% + 10px); /* 调整为 -7% 并增加 10px */
        left: calc(50% - 50px); /* 水平居中 */
        overflow: hidden;
        background-color: #4387f8;
      }
      .release_number {
        padding-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px;
        color: #908e8d;
        .release_number_mid{
          max-width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
      .copy {
        line-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          width: 200px;
        }
      }
      .tips {
        line-height: calc(100vw * 35 / 1920);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px;
        color: #908e8d;
      }
      .donw_btn {
        line-height: calc(100vw * 35 / 1920);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px;
        font-size: 18px;
        color: #4387f8;
        cursor: pointer;
        font-weight: bolder;
      }
      .cornered-div {
        display: flex;
        justify-content: center;
        align-items: center;
        .corner-borders {
          width: 200px;
          height: 200px;
          position: relative;
          border-radius: 8px;
          overflow: hidden;
          box-sizing: border-box;
          padding: 18px;
          .corner_1 {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            border-top: 2px solid #6ca1fa; /* 上边框 */
            border-left: 2px solid #6ca1fa; /* 左边框 */
          }
          .corner_2 {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 30px;
            border-top: 2px solid #6ca1fa; /* 上边框 */
            border-right: 2px solid #6ca1fa; /* 右边框 */
          }
          .corner_3 {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 30px;
            height: 30px;
            border-bottom: 2px solid #6ca1fa; /* 下边框 */
            border-left: 2px solid #6ca1fa; /* 左边框 */
          }
          .corner_4 {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 30px;
            height: 30px;
            border-bottom: 2px solid #6ca1fa; /* 下边框 */
            border-right: 2px solid #6ca1fa; /* 右边框 */
          }
          .qr {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .footer {
        margin-top: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        .type_box {
          width: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .type_title {
          color: #606266;
          font-weight: 700;
          user-select: none;
          cursor: pointer;
          font-size: 14px;
          line-height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid #fff;
          i {
            font-size: 20px;
          }
        }
        .bor_rr {
          border-right: 2px dotted #ccc;
        }

        .chose {
          color: #4387f8;
          border-color: #4387f8;
        }
      }
    }
  }
}
</style>
