<!-- 预览 -->
<template>
  <div style="background-color: #fff;min-height:100vh">
    <asideIcon v-if="showHeader"></asideIcon>
    <userHeaderFix v-if="showHeader"></userHeaderFix>

    <div id="preview">
      <div class="main" v-if="shows">
        <div class="text_zone">
          <div class="topic_name">{{ base.news_name }}</div>
          <div class="time_zone">
            <span>{{ base.release_start_time }}</span>
            <span style="margin-left: 40px"
              >分类: &nbsp; {{ base.category_name }}</span
            >
          </div>
          <div class="content">
            <div
              class="deit_zone_main"
              :style="`margin-bottom:${base.text_space || 0}px`"
              v-for="(item, index) in base.news_info"
              :key="index"
            >
              <div class="deit_zone_main_type1" v-if="item.typesetting == 1">
                <!-- 图片域 左右排序 -->
                <div v-for="(t, i) in item.adv_list" :key="i" class="media">
                  <img :src="t.image_url" />
                  <p>{{ t.text }}</p>
                </div>
              </div>
              <div
                class="deit_zone_main_type2"
                v-if="item.typesetting == 2 && item.type == 'pic'"
              >
                <!-- 图片域 上下排序 -->
                <div
                  v-for="(t, i) in item.adv_list"
                  :key="i"
                  style="margin: 8px 0"
                >
                  <img :src="t.image_url" />
                  <p>{{ t.text }}</p>
                </div>
              </div>
              <div class="deit_zone_main_type2" v-if="item.type == 'vdo'">
                <!-- 视频域 -->
                <div
                  v-for="(t, i) in item.adv_list"
                  :key="i"
                  style="margin: 8px 0"
                >
                  <video :src="t.image_url" controls></video>
                  <p>{{ t.text }}</p>
                </div>
              </div>
              <!-- 预留文本域区域 -->
              <div v-if="item.type == 'txt'">
                <div v-html="item.textContent" class="txt_zone"></div>
              </div>
            </div>
            <div>
              <h4 style="margin-bottom:18px">查看附件</h4>
              <div v-if="base.enclosure">
                <p
                  :style="`margin-bottom:${base.enclosure_space || 0}px`"
                  v-for="(k, i) in base.enclosure.list"
                  :key="i"
                >
                  <span class="links" @click="look(k)">{{ k.full_name }}</span>
                </p>
              </div>
            </div>
            <div class="share_bar">
              <!-- <i class="el-icon-share"></i>
              <i class="el-icon-eleme"></i>
              <i class="el-icon-bangzhu"></i> -->
            </div>
          </div>
          <!-- <div class="footer">
            <div>上一篇:</div>
            <div>下一篇:</div>
          </div> -->
        </div>
      </div>
    </div>

    <footers v-if="showHeader" class="footer-margin" :key="123" />
  </div>
</template>

<script>
import asideIcon from "@/components/asideIcon";
import footers from "@/components/footer/footer_tpl.vue";
import userHeaderFix from "./userHeaderFix";
export default {
  name: "",
  components: {
    userHeaderFix,
    footers,
    asideIcon,
  },
  data() {
    return {
      base: null,
      shows: null,
      showHeader: true,
    };
  },
  created() {
    this.getDetails();
  },
  mounted() {
    this.$store.commit("updatePageNav", [
      //渲染面包屑
      { icon: "fa fa-cog", name: "新闻管理" },
      { name: "新闻预览" },
    ]);
    if (window.parent == window) {
      this.showHeader = true;
      // 当前页面不在iframe中
    } else {
      this.showHeader = false;
      // 当前页面在iframe或者frameset中
    }
  },
  methods: {
    getDetails() {
      if (this.$route.query.id) {
        this.$axios
          .get(`/news_manage/news/preview/${this.$route.query.id}`)
          .then((res) => {
            if (res.code == 200) {
              console.log(res.data, "basemsg编辑拿到的数据");
              this.base = res.data;
              this.shows = false;
              this.$nextTick(() => {
                this.shows = true;
              });
            }
          });
      }
      console.log(this.base);
    },
    look(k) {
      if (window.parent == window) {
        window.open(k.image_url);
      } else {
        // 当前页面在iframe或者frameset中
        window.parent.postMessage(
          { type: "lookFile", id: k.attachment_id, url: k.image_url },
          "*"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#preview {
  background-color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0;
  .main {
    border: 1px solid #dce0e7;
    width: 1060px;
    // margin: 40px auto;
    min-height: 200px;
    .text_zone {
      width: 800px;
      margin: 80px auto;
      .topic_name {
        font-size: 36px;
        text-align: left;
        line-height: 52px;
        max-width: 800px;
        font-weight: bold;
        color: #101010;
      }
      @media only screen and (max-width: 600px) {
        .topic_name {
          font-size: 22px;
          line-height: 1.4;
        }
      }
      .time_zone {
        margin: 20px 0 16px;
        font-size: 14px;
        color: #101010;
      }
      .content {
        padding: 0;
        padding-top: 16px;
        border-top: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
        margin-bottom: 10px;
        .deit_zone_main {
          width: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          img {
            width: 100% !important;
          }
          .deit_zone_main_type1 {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            flex-wrap: wrap;
            margin: 5px 0;
            .media {
              width: 48.6%;
              height: 100%;
              box-sizing: border-box;
              img {
                width: 100%;
                object-fit: contain;
                background-color: #000;
              }
              p {
                width: 100%;
                line-height: 20px;
                margin: 0;
                font-size: 14px;
                color: #666;
                padding: 0 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                box-sizing: border-box;
              }
            }
          }
          .deit_zone_main_type2 {
            width: 100%;
            margin: 5px 0;
            box-sizing: border-box;
            img {
              width: 100%;
              object-fit: contain;
              background-color: #000;
            }
            p {
              width: 100%;
              line-height: 20px;
              margin: 0;
              font-size: 14px;
              color: #666;
              text-align: center;
              padding: 0 15px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              box-sizing: border-box;
            }
            video {
              height: auto;
              width: 100%;
            }
          }
          p {
            word-break: break-all;
          }
        }
      }
      .footer {
        margin: 20px 0;
        div {
          width: 100%;
          background-color: #fafafa;
          height: 40px;
          margin-bottom: 12px;
          padding: 10px 20px;
          box-sizing: border-box;
          border-radius: 12px;
          font-size: 14px;
        }
      }
    }
    @media only screen and (max-width: 800px) {
      .text_zone {
        margin-top: 10px;
        width: auto;
        padding: 10px;
      }
    }
    .links {
      font-size: 14px;
      color: #4387f8;
      cursor: pointer;
      margin-left: 32px;
    }
    .share_bar {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 20px 0 50px 0;
      i {
        margin: 0 10px;
        &:hover {
          color: #4387f8;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .main {
      border: none;
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.txt_zone {
  img {
    max-width: 100% !important;
  }
}
</style>
