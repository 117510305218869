<template>
	<div ref="qianBottomNav" class="qian-bottom-nav"
		:class="{ 'mobile': lookType == 2, 'white': data.themeType == 'white', 'black': data.themeType == 'black' }">
		<div class="flex">
			<!-- 导航列表 -->
			<div class="nav-list">
				<div class="category" :class="{ 'active': category.active }"
					v-for="(category, index) in navList.slice(0, 4)" :key="'category-' + index">
					<h4 class="title" @click="handleExpand(category)">
						<span>{{ category.title }}</span>
						<i class="arrow el-icon-arrow-right" v-if="lookType == 2"></i>
					</h4>
					<ul>
						<li class="nav hover" v-for="nav in category.child" :key="index + '-' + nav.id"
							@click="handleOpenLink(nav)">{{ nav.title }}</li>
					</ul>
				</div>
			</div>
			<!-- 联系我们 -->
			<div class="concats">
				<h4 v-if="lookType == 1">联系我们</h4>
				<ul>
					<li class="concat-item" v-for="(item, index) in concatList" :key="'concat-' + index">
						<div class="mask" v-if="item.type == 1 && lookType == 2" @click="handleOpenMap(item)"></div>
						<el-tooltip v-if="item.type == 1" :content="item.address">
							<div class="map-wrap" @click="handleOpenMap(item)" id="baiduMapView"></div>
						</el-tooltip>
						<img v-if="item.type == 2 && item.img_path" :src="item.img_path" alt="" />
						<span>{{ item.name }}</span>
					</li>
				</ul>
			</div>
		</div>
		<!-- Copyright -->
		<div class="copyright">
			<h4 v-html="copyright"></h4>
			<a class="hover" href="https://beian.miit.gov.cn" target="_blank">{{ beian.beian }}</a>
		</div>
		<!-- 地图弹窗 -->
		<el-dialog title="公司地址" :visible.sync="mapVisible" :width="lookType == 1 ? '640px' : '90vw'">
			<div id="mapView" :style="{ 'width': lookType == 1 ? '600px' : '100%', 'height': lookType == 1 ? '400px' : '55vh' }"></div>
		</el-dialog>
	</div>
</template>

<script>
let mapDialog = null;
export default {
	props: {
		data: {
			type: Object,
			default: () => {
				return {
					themeColor: "#5087EC",
					themeType: "white",
				};
			},
		},
		lookType: {
			type: Number,
			default: 1,
		},
	},
	name: "qianBottomNav",
	data() {
		return {
			navList: [], // 底部导航列表
			concatList: [], // 联系我们
			copyright: "",
			beian: {}, // 备案信息
			mapVisible: false,
		}
	},
	created() {
		this.getData();
	},
	mounted() {
		this.$refs.qianBottomNav.style.setProperty("--theme-color", this.data.themeColor);
	},
	methods: {
		// 初始化地图
		initMap(row) {
			let { lng, lat } = row;
			this.addressMsg = null;
			this.$nextTick(() => {
				let point = new BMapGL.Point(lng || 104.039, lat || 30.687);
				let mapView = new BMapGL.Map("baiduMapView");
				mapView.centerAndZoom(point, 15);
				mapView.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
				mapView.clearOverlays(); // 清除点
				let marker = new BMapGL.Marker(point); // 创建标注
				let opts = {
					position: point, // 指定文本标注所在的地理位置
					offset: new BMapGL.Size(30, -30), // 设置文本偏移量
					width: 200,
					height: 120,
					title: "公司地址",
				};
				new BMapGL.InfoWindow("地址：" + row.address, opts);
				mapView.addOverlay(marker);
			});
		},
		// 打开地图弹窗
		handleOpenMap(row) {
			this.mapVisible = true;
			let { lng, lat } = row;
			this.addressMsg = null;
			this.$nextTick(() => {
				let point = new BMapGL.Point(lng || 104.039, lat || 30.687);
				mapDialog = new BMapGL.Map("mapView");
				mapDialog.centerAndZoom(point, 15);
				mapDialog.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
				mapDialog.clearOverlays(); // 清除点
				let marker = new BMapGL.Marker(point); // 创建标注
				let opts = {
					position: point, // 指定文本标注所在的地理位置
					offset: new BMapGL.Size(30, -30), // 设置文本偏移量
					width: 200,
					height: 60,
					title: "联系我们",
				};
				let infoWindow = new BMapGL.InfoWindow("公司地址：" + row.address, opts);
				// 点标记添加点击事件
				marker.addEventListener("click", function () {
					mapDialog.openInfoWindow(infoWindow, point); // 开启信息窗口
				});
				mapDialog.addOverlay(marker);
			});
		},
		// mobile 点击分类展开导航列表
		handleExpand(category) {
			if (this.lookType == 2) category.active = !category.active;
		},
		// 点击打开链接
		handleOpenLink(item) {
			if (item.url) window.open(item.url, "_blank");
		},
		// 获取底部数据
		getData() {
			// 导航、联系方式
			this.$axios.get("/setting/page_footer").then((res) => {
				if (res.code == 200) {
					let data = res.data.detail_set || {};
					this.navList = data.category_nav.map(item => {
						item.active = this.lookType == 1;
						return item;
					});
					let concat = data.contact || [];
					this.concatList = [];
					for (let key in concat) {
						if (concat[key].is_on) {
							if (key == "company") {
								concat[key].type = 1
								this.$nextTick(() => {
									this.initMap(concat[key]);
								})
							}
							this.concatList.push(concat[key]);
						}
					}
					this.copyright = data.copyright;
				}
			});
			// 备案信息
			this.$axios.get('/base/beian').then(res => {
				if (res.code == 200) {
					this.beian = res.data || {};
				}
			})
		}
	},
};
</script>

<style lang="scss" scoped>
.qian-bottom-nav {
	--theme-color: #5087EC;
	padding: 20px;
	font-size: 14px;

	.flex {
		display: flex;
	}

	.nav-list {
		flex: 1;
		display: flex;

		.category {
			flex: 1;
			text-align: center;

			.title {
				font-size: 18px;
				margin-bottom: 30px;

				.arrow {
					width: 18px;
					height: 18px;
					transition: 0.3s;
				}
			}

			li {
				line-height: 30px;
			}
		}
	}

	.concats {
		width: 480px;

		h4 {
			font-size: 18px;
			text-align: center;
		}

		ul {
			display: flex;
			justify-content: space-evenly;

			li {
				width: 25%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-top: 30px;

				.map-wrap {
					width: 70px;
					height: 70px;
					margin-bottom: 5px;
					cursor: pointer;
				}

				img {
					width: 70px;
					height: 70px;
					margin-bottom: 5px;
				}
			}
		}
	}

	.copyright {
		text-align: center;
		padding-top: 25px;

		h4 {
			font-size: 16px;
		}

		a {
			color: inherit;
			text-decoration: underline;
		}
	}

	.hover:hover {
		cursor: pointer;
		color: var(--theme-color);
	}

	&.white {
		background: #fff;
		color: #101010;

		.copyright {
			color: #666;
		}
	}

	&.black {
		background: #333;
		color: #fff;
	}

	&.mobile {

		// 移动端样式兼容
		.flex {
			flex-direction: column;
		}

		.nav-list {
			flex-direction: column;

			.category {
				text-align: left;

				.title {
					display: flex;
					justify-content: space-between;
					padding: 16px 0;
					margin-bottom: 0;
				}

				ul {
					display: none;
				}

				&.active {
					.title {
						.arrow {
							transform: rotate(90deg);
						}
					}

					ul {
						display: block;
					}
				}
			}
		}

		.concats {
			width: 100%;

			.concat-item {
				position: relative;

				.mask {
					position: absolute;
					top: 0;
					left: 50%;
					width: 120px;
					height: 120px;
					transform: translateX(-50%);
					z-index: 999;
					cursor: pointer;
				}
			}

			h4 {
				font-size: 18px;
				text-align: center;
			}

			ul {
				flex-wrap: wrap;
				justify-content: flex-start;

				li {
					width: 50%;

					.map-wrap {
						width: 120px;
						height: 120px;
						margin-bottom: 0 auto 5px;
					}

					img {
						width: 120px;
						height: 120px;
						margin-bottom: 0 auto 5px;
					}
				}
			}
		}

		.copyright {
			h4 {
				font-size: 14px;
			}
		}
	}
}
</style>
