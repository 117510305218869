import axios from "@/server/ajax.js";
import store from "@/store/index.js";
// const platform = store.state.cid; // 1为平台，2为租户
// 获取底部数据
export function page_footer() {
  return axios.get("/setting/page_footer");
}
export function base_beian() {
  return axios.get("/base/beian");
}

// 获取首页数据
export function custHomeGetData(params) {
  return axios.get("/app_shop/public/plat_home/get_data", params);
}

// 商品页-获取分类信息
export function get_shop_category(params) {
  return axios.postJSON("/app_shop/public/plat_home/get_plat_category", params);
}

// 商品页-获取品牌
// export function get_brand(params) {
//   if (store.state.cid > 0) {
//     return axios.postJSON("/shop/public/cust_home/get_brand", params);
//   } else {
//     return axios.postJSON("/shop/public/plat_home/get_brand", params);
//   }
// }
export function get_brand() {
}

// 商品页-获取规格
export function get_spec(params) {
  if (store.state.cid > 0) {
    return axios.postJSON("/shop/public/cust_home/get_spec", params);
  } else {
    return axios.postJSON("/shop/public/plat_home/get_spec", params);
  }
}

// 商品详情
export function get_sku_detail(params) {
  if (store.state.cid > 0) {
    return axios.postJSON("/shop/public/cust_home/get_sku_detail", params);
  } else {
    return axios.postJSON("/shop/public/plat_home/get_sku_detail", params);
  }
}

// 商品列表
export function get_sku_list(params) {
  // if (store.state.cid > 0) {
  //   return axios.postJSON("/shop/public/cust_home/get_sku_list", params);
  // } else {
  //   return axios.postJSON("/shop/public/plat_home/get_sku_list", params);
  // }
  
  return axios.postJSON("/app_shop/public/plat_home/get_product", params);
}

// 搜索下来列表
export function get_search(params) {
  if (store.state.cid > 0) {
    return axios.postJSON("/shop/public/cust_home/search", params);
  } else {
    return axios.postJSON("/shop/public/plat_home/search", params);
  }
}

// 商品页-获取apaas -新
export function get_sku_apaas(params) {
  // if (store.state.cid > 0) {
  //   return axios.postJSON("/shop/public/cust_home/get_sku_apaas", params);
  // } else {
  //   return axios.postJSON("/shop/public/plat_home/get_sku_apaas", params);
  // }
  return axios.get('/app_shop/public/plat_home/get_category_apaas',params)
}

// 商品分类
export function get_plat_shop_category(params) {
  return axios.postJSON("/shop/public/plat_home/get_shop_category", params);
}

// 平台logo和名称
export function name_logo() {
  return axios.get("/setting/platform/name_logo");
}
// 获取租户名称
export function company_detail(params) {
  return axios.get("/base/company/detail", params);
}
